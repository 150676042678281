const BLACK = "#000";
const WHITE = "#fff";
const WHITE_RGB = "255,255,255";
const RICHBLACK = "#002233";
const RICHBLACK_RBG = "0,34,51";
const VDEP_BLUE_RGB = "0,173,241";
const VDEP_SECONDARY_BLUE_RGB = "1,91,128";
const VDEP_TERTIARY_BLUE_RGB = "212,243,250";
const VDEP_TERTIARY_BLUE_DARK = "#BCEBF5";
const VDEP_QUARTIARY_BLUE = "#96d8e9";
const VDEP_DARK_BLUE_RGB = "0,53,74";
const VDEP_GREEN = "#BDED9D";

export const COLOR = {
  BLACK,
  WHITE,
  WHITE_RGB,
  RICHBLACK,
  RICHBLACK_RBG,
  VDEP_BLUE: `rgba(${VDEP_BLUE_RGB}, 1)`,
  VDEP_BLUE_RGB,
  VDEP_SECONDARY_BLUE_RGB,
  VDEP_TERTIARY_BLUE_RGB,
  VDEP_DARK_BLUE_RGB,
  VDEP_SECONDARY_BLUE: `rgba(${VDEP_SECONDARY_BLUE_RGB}, 1)`,
  VDEP_SECONDARY_BLUE_05: `rgba(${VDEP_SECONDARY_BLUE_RGB}, .5)`,
  VDEP_TERTIARY_BLUE: `rgba(${VDEP_TERTIARY_BLUE_RGB}, 1)`,
  VDEP_TERTIARY_BLUE_05: `rgba(${VDEP_TERTIARY_BLUE_RGB}, .5)`,
  VDEP_TERTIARY_BLUE_DARK,
  VDEP_QUARTIARY_BLUE,
  VDEP_DARK_BLUE: `rgba(${VDEP_DARK_BLUE_RGB}, 1)`,
  VDEP_DARK_BLUE_05: `rgba(${VDEP_DARK_BLUE_RGB}, .5)`,
  VDEP_GREEN,
  RICHBLACK_03: `rgba(${RICHBLACK_RBG},.03)`,
  RICHBLACK_10: `rgba(${RICHBLACK_RBG},.1)`,
  RICHBLACK_15: `rgba(${RICHBLACK_RBG},.15)`,
  RICHBLACK_20: `rgba(${RICHBLACK_RBG},.2)`,
  RICHBLACK_50: `rgba(${RICHBLACK_RBG},.5)`,
  RICHBLACK_80: `rgba(${RICHBLACK_RBG},.8)`,
  WHITE_0: `rgba(${WHITE_RGB}, .0)`,
  WHITE_15: `rgba(${WHITE_RGB}, .15)`,
  RICKBLACK_15: `rgba(${RICHBLACK_RBG}, .15)`,
};

// export const STYLES = {
//   FONT_FAMILY: `'Source Sans Pro',sans-serif`,
//   MARGIN: 24,
//   HEADER_HEIGHT: 130,
//   TITLES_HEIGHT: 60,
//   ITEM_MODULE_HEIGHT: 65,
//   ITEM_GROUP_HEIGHT: 50,
//   ITEM_HEIGHT: 50,
//   LIST_ITEM_HEIGHT: 70,
//   LIST_ITEM_GROUP_HEIGHT: 30,
//   BOTTOM_BAR_HEIGHT: 90,

//   COLOR_ERROR: "#F4562B",
//   COLOR_OK: "#74d200",
//   COLOR_BORDER: "rgba(0, 34, 51, 0.10)",

//   COLOR_TITLE: "rgba(0, 34, 51, 0.80)",
//   COLOR_GREY_TEXT: "rgba(0, 34, 51, 0.5)",
//   COLOR_GREY_TEXT_HOVER: "rgba(0, 34, 51, 0.80)",
//   COLOR_GREY_TEXT_INPUT: "rgba(0, 34, 51, 0.80)",

//   COLOR_BACKGROUND: "#fff",
//   COLOR_BACKGROUND_DARK: "rgba(0, 34, 51, 0.80)",

//   COLOR_INPUT_PLACEHOLDER: "rgba(0, 34, 51, 0.30)",
//   COLOR_INPUT_BORDER_FOCUS: "rgba(0, 34, 51, 0.25)",
//   COLOR_INPUT_ICON: "rgba(0, 34, 51, 0.30)",
//   COLOR_INPUT_ICON_FOCUS: "rgba(0, 34, 51, 0.50)",
// };
